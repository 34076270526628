import { ToastOptions, toastController } from '@ionic/core';
import { translate } from './translate';
import { AnalyticsEvent, trackAnalyticsEvent } from '../services/analytics';
import { routeInApp } from './route-in-app';

export async function showPermissionAlert() {
  trackAnalyticsEvent(AnalyticsEvent.missing_permission_viewed, {
    url: location.href,
  });
  const options: ToastOptions = {
    header: translate('MISSING_PERMISSION'),
    message: translate('MISSING_PERMISSION_GROUP_DESC'),
    duration: 3000,
    buttons: [translate('DONE')],
    color: 'danger',
  };
  const toast = await toastController.create(options);
  if (location.href.includes('/groups')) {
    routeInApp('/groups');
  } else {
    routeInApp('/sign-in');
  }
  return toast.present();
}
